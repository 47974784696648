import styled, { createGlobalStyle } from "styled-components";

import Gothic from "../assets/fonts/century_gothic/GOTHIC.TTF";
import GothicBold from "../assets/fonts/century_gothic/GOTHICB.TTF";
import GothicBoldItalic from "../assets/fonts/century_gothic/GOTHICBI.TTF";
import GothicItalic from "../assets/fonts/century_gothic/GOTHICI.TTF";
import Obrazec from "../assets/fonts/obrazec/obrazec.otf";
import Robotica from "../assets/fonts/robotica/Robotica.otf";
import { device } from "../constants/device";

export const GlobalStyle = createGlobalStyle`
  /* CUSTOM PROPERTIES - VARIABLES
  ============================= */
  :root {
    --primary-color:#00CBC4;
    --secondary-color: #FF408E;
    --black-color: #010101;
    --bg-loading: #1E1E1E;
    --gray-text: #C3C3C3;
    --gray-dark-text: #777777;
    --gray-sec-color: #A3A3A3;
    --line-horizontal: #A3A3A380;
    --white-color: #ffffff;
    --blue-color: #007D81;
    --blue-secondary-color: #2A7485;


    /* font size responsive 390px - 1240px*/
    --fs-small: clamp(0.75rem, calc(0.66rem + 0.35vw), 0.94rem); //15px - 12px
    --fs-normal: clamp(1.00rem, calc(0.25rem + 1.56vw), 1.25rem); // 20px - 16px
    --fs-medium: clamp(1.25rem, calc(0.96rem + 1.18vw), 1.88rem); //30px - 20px
    --fs-large: clamp(1.81rem, calc(1.10rem + 2.94vw), 3.38rem); //29px - 54px
    --fs-largeone: clamp(1.88rem, calc(1.30rem + 2.35vw), 3.13rem);; //30px - 50px
    --fs-largetwo: clamp(1.88rem, calc(1.01rem + 3.53vw), 3.75rem); //30px - 60px
    --fs-largethree: clamp(1.25rem, calc(0.10rem + 4.71vw), 3.75rem); //20px - 60px
    --fs-xl: clamp(3.13rem, calc(1.98rem + 4.71vw), 5.63rem); //50px - 90px
    --fs-xxl: clamp(3.75rem, calc(2.32rem + 5.88vw), 6.88rem);; //60px - 110px

    /* Image card responsive */
    --img-card: clamp(4.19rem, calc(1.81rem + 9.76vw), 9.38rem); 
    --border-inner: clamp(7.13rem, calc(3.05rem + 16.71vw), 16.00rem);
    --border-offset: clamp(0.56rem, calc(0.19rem + 1.53vw), 1.38rem);
    --img-logo: clamp(7.50rem, calc(5.56rem + 7.94vw), 11.72rem); // 100px -150px
    --img-logo-dna: clamp(5.69rem, calc(4.57rem + 4.59vw), 8.13rem); //90px - 130px

  }

  /* FONTS
  ============================= */
  /* Century Gothic */
  @font-face {
    font-family: 'Century Gothic';
    src: local('Century Gothic'), local('Century Gothic'),
    url(${Gothic}) format('ttf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Century Gothic Bold';
    src: local('Century Gothic Bold'), local('Century Gothic Bold'),
    url(${GothicBold}) format('ttf');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Century Gothic Bold Italic';
    src: local('Century Gothic Bold Italic'), local('Century Gothic Bold Italic'),
    url(${GothicBoldItalic}) format('ttf');
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: 'Century Gothic Italic';
    src: local('Century Gothic Italic'), local('Century Gothic Italic'),
    url(${GothicItalic}) format('ttf');
    font-weight: normal;
    font-style: italic;
  }

   /* Obrazec */
  @font-face {
    font-family: 'Obrazec';
    src: local('Obrazec'), local('Obrazec'),
    url(${Obrazec}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

     /* Robotica */
  @font-face {
    font-family: 'Robotica';
    src: local('Robotica'), local('Robotica'),
    url(${Robotica}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }


/* GLOBALS
  ============================= */
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: 16px;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  body {
    font-family: 'Century Gothic', sans-serif;
    margin: 0;
    padding: 0;
  }

  figure {
    margin: 0;
  }
  img, video {
    width: 100%;
    height: auto;
  }

  p {
    margin: 0;
    line-height: 1;
  }
  
  // Style SCROLL - No aplica firefox
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgb(0 0 0);
    border-radius: 10px;
    background: black;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgb(124 124 124);
}

  /* Responsive */

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .col, .col-12 {
    flex-basis: 100%;
  }
  .col-8 {
    flex-basis: 66.6%;
  }
  .col-6 {
    flex-basis: 50%;
  }
  .col-4 {
    flex-basis: 33.3%;
  }
  .col-3 {
    flex-basis: 25%;
  }
  .col-2 {
    flex-basis: 16.66%;
  }
  .col-1 {
    flex-basis: 16.66%;
  }

  @media ${device.laptop} {
    .col-xl-12 {
      flex-basis: 100%;
    }
    .col-xl-8 {
      flex-basis: 66.6%;
    }
    .col-xl-6 {
      flex-basis: 50%;
    }
    .col-xl-4 {
      flex-basis: 33.3%;
    }
    .col-xl-3 {
      flex-basis: 25%;
    }
    .col-xl-2 {
      flex-basis: 16.66%;
    }
    .col-xl-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.tabletL} {
    .col-l-12 {
      flex-basis: 100%;
    }
    .col-l-8 {
      flex-basis: 66.6%;
    }
    .col-l-6 {
      flex-basis: 50%;
    }
    .col-l-4 {
      flex-basis: 33.3%;
    }
    .col-l-3 {
      flex-basis: 25%;
    }
    .col-l-2 {
      flex-basis: 16.66%;
    }
    .col-l-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.tablet} {
    .col-m-12 {
      flex-basis: 100%;
    }
    .col-m-8 {
      flex-basis: 66.6%;
    }
    .col-m-6 {
      flex-basis: 50%;
    }
    .col-m-4 {
      flex-basis: 33.3%;
    }
    .col-m-3 {
      flex-basis: 25%;
    }
    .col-m-2 {
      flex-basis: 16.66%;
    }
    .col-m-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.mobileL} {
    .col-s-12 {
      flex-basis: 100%;
    }
    .col-s-8 {
      flex-basis: 66.6%;
    }
    .col-s-6 {
      flex-basis: 50%;
    }
    .col-s-4 {
      flex-basis: 33.3%;
    }
    .col-s-3 {
      flex-basis: 25%;
    }
    .col-s-2 {
      flex-basis: 16.66%;
    }
    .col-s-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.mobileM} {
    .col-xs-12 {
      flex-basis: 100%;
    }
    .col-xs-8 {
      flex-basis: 66.6%;
    }
    .col-xs-6 {
      flex-basis: 50%;
    }
    .col-xs-4 {
      flex-basis: 33.3%;
    }
    .col-xs-3 {
      flex-basis: 25%;
    }
    .col-xs-2 {
      flex-basis: 16.66%;
    }
    .col-xs-1 {
      flex-basis: 16.66%;
    }
  }

  /* others */
  .text-uppercase {
    text-transform: uppercase;
  }
  @media ${device.tabletL} {
    html {
      overflow-x: hidden;
    }
  }
`;

export const PageWrapper = styled.div`
  min-height: calc(100vh - 601px);
`;
export const Container = styled.div`
  max-width: 1680px;
  margin: auto;
  @media ${device.desktop} {
    max-width: 1600px;
  }
  @media ${device.laptop} {
    max-width: 1250px;
  }
`;
