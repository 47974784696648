export const exploreWorlds = [
  {
    id: "world-1",
    imgUrl: "/planet-01.png",
    title: "DNA Legends",
  },
  {
    id: "world-2",
    imgUrl: "/planet-02.png",
    title: "Sefty Mansion ",
  },
  {
    id: "world-3",
    imgUrl: "/planet-03.png",
    title: "Cleo E-Learnig Platform",
  },
  {
    id: "world-4",
    imgUrl: "/planet-04.png",
    title: "MITA Assistant",
  },
];

export const startingFeatures = [
  {
    imgUrl: "/passion.svg",
    title: "Passion",
  },
  {
    imgUrl: "/plant.svg",
    title: "Sustainability",
  },
  {
    imgUrl: "/team.svg",
    title: "Team",
  },
  {
    imgUrl: "/rocket.svg",
    title: "Vanguard",
  },
];

export const newFeatures = [
  {
    imgUrl: "/vrpano.svg",
    title: "Our Approach",
    subtitle:
      "We begin with a thorough analysis of your requirements, followed by the design and implementation of tailored solutions.  ",
  },
  {
    imgUrl: "/check.svg",
    title: "Why Choose Us",
    subtitle:
      "At DNA UNIVERSE, we combine deep technical expertise with a passion for innovation. Our customized solutions are designed to meet your unique needs. ",
  },
];

export const insights = [
  {
    imgUrl: "/planet-06.png",
    title: "The launch of the Metaverse makes Elon musk ketar-ketir",
    subtitle:
      "Magna etiam tempor orci eu lobortis elementum nibh tellus molestie. Diam maecenas sed enim ut sem viverra alique.",
  },
  {
    imgUrl: "/planet-07.png",
    title: "7 tips to easily master the madness of the Metaverse",
    subtitle:
      "Vitae congue eu consequat ac felis donec. Et magnis dis parturient montes nascetur ridiculus mus. Convallis tellus id interdum",
  },
  {
    imgUrl: "/planet-08.png",
    title: "With one platform you can explore the whole world virtually",
    subtitle:
      "Quam quisque id diam vel quam elementum. Viverra nam libero justo laoreet sit amet cursus sit. Mauris in aliquam sem",
  },
];

export const socials = [
  {
    name: "twitter",
    url: "/twitter.svg",
    link: "https://x.com/dnauniverse_inc",
  },
  {
    name: "linkedin",
    url: "/linkedin.svg",
    link: "https://www.linkedin.com/company/dna-universe/",
  },
  {
    name: "instagram",
    url: "/instagram.svg",
    link: "https://www.instagram.com/dnauniverse_inc/",
  },
  {
    name: "facebook",
    url: "/facebook.svg",
    link: "https://www.facebook.com/",
  },
];
