export const translationsES = {
  /* Section Coming Soon */
  'COMING SOON': {
    title: 'Aqui comienza un nuevo',
    universe: 'Universo',
    'Expanding universe': 'Universo en expansión',
    'Come back': 'Vuelve pronto (tal vez sólo unas pocas semanas)',
  },
  /* Footer */
  footer: {
    title: 'Sitio web de DNA Universe. Todos los derechos reservados',
  },
  /* Common */
  'Contact us': 'Contactenos',
};
