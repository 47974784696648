import React from "react";
import { NavigationApp } from "./routers";
import "./index.css";
import { GlobalStyle } from "./styles/globalStyles";
function App() {
  return (
    <React.Fragment>
      <GlobalStyle />
      <NavigationApp />
    </React.Fragment>
  );
}

export default App;
