import React, { useState, useEffect } from "react";
import { Container } from "../styles/globalStyles";
import { SectionMain, StylesHome } from "../views/Home/styles";

import { useTranslation } from "react-i18next";

const Hero2 = () => {
  const { t } = useTranslation();

  const [isVisibleScroll, setIsVisibleScroll] = useState(true);
  const [widthDocument, setWidthDocument] = useState(true);
  useEffect(() => {
    let box = document.getElementById("root");
    setWidthDocument(box.offsetWidth);
    scrollSpy();
  }, []);

  const scrollSpy = () => {
    const $sections = document.querySelectorAll("section[data-scroll-spy]");

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target.id === "main-section") {
            setIsVisibleScroll(true);
          } else {
            setIsVisibleScroll(false);
          }
          entry.target.classList.add("active-animation");
        } else {
          setIsVisibleScroll(true);
          entry.target.classList.remove("active-animation");
        }
      });
    };

    const observer = new IntersectionObserver(callback, {
      //root
      //rootMargin: ("-250px"),
      threshold: [0.25, 0.8], //porcentajes de visualizacion
    });

    $sections.forEach((el) => {
      observer.observe(el);
    });
  };
  return (
    <StylesHome>
      <Container>
        <SectionMain data-scroll-spy id="main-section">
          <article className="section-description">
            <div className="section-planet section-animation-top">
              <div className="title">
                <p className="section-animation-bottomX2">
                  {t("COMING SOON.title")}
                </p>
                <p className="bigX section-animation-bottomX2">
                  {t("COMING SOON.universe")}
                </p>
              </div>
            </div>
          </article>
        </SectionMain>
      </Container>
    </StylesHome>
  );
};

export default Hero2;
