import React from "react";
import { ImageContainer, StylesLoading } from "./styles";
import Logo from "../../assets/img/LogoUniverse.svg";

export const LoadingStartPage = (loading) => {
  return (
    <>
      <StylesLoading loading={loading}>
        <ImageContainer>
          <img src={Logo} alt="loading-logo" />
          <div className="black-bg-animation"></div>
        </ImageContainer>
      </StylesLoading>
    </>
  );
};
