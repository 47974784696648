import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translationsEN } from "./English";
import { translationsES } from "./Spanish";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translationsEN,
  },
  es: {
    translation: translationsES,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
