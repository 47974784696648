import React, { Fragment, useEffect, useState } from "react";

import { HashRouter, Route, Routes } from "react-router-dom";
import { LoadingStartPage } from "../components/Loadings/LoadingStartPage";

import { PageWrapper } from "../styles/globalStyles";
import Home from "../views/Home";
import { NotFound } from "../views/Not Found";
import { StylesPages } from "./styles";

const NotAuthenticatedUser = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <Fragment>
      <LoadingStartPage loading={loading} />
      <StylesPages>
        <PageWrapper>{children}</PageWrapper>
      </StylesPages>
    </Fragment>
  );
};

export const NavigationApp = () => {
  return (
    <HashRouter>
      <NotAuthenticatedUser>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </NotAuthenticatedUser>
    </HashRouter>
  );
};
