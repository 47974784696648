import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Buttons';
import { StylesNotFound } from './styles';

export const NotFound = () => {
  return (
    <React.Fragment>
      <StylesNotFound>
        <div>
          <h2>Page Not Found</h2>
          <Link to={'/'}>
            <Button text="Return To Home" />
          </Link>
        </div>
      </StylesNotFound>
    </React.Fragment>
  );
};
