"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { navVariants } from "../utils/motion";

const Navbar = () => (
  <motion.nav
    variants={navVariants}
    initial="hidden"
    whileInView="show"
    className={` py-3 lg:px-16 px-2 relative`}
  >
    <div className={`${styles.innerWidth} mx-auto`}>
      <img
        src="assets/LogoNavbar.svg"
        alt="logo"
        className=" object-contain relative z-10 lg:w-[180px] lg:h-[180px]  w-24 h-24 "
      />
    </div>
    <div className="absolute w-[50%] h-[120%] inset-0 gradient-01" />
  </motion.nav>
);

export default Navbar;
