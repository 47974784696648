import styled, { keyframes } from "styled-components";
import { device } from "../../constants/device";

/* styles */
export const StylesHome = styled.section`
  [id] {
    scroll-margin-top: 140px;
  }
  color: white;
  padding-bottom: 50px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -80px;
  @media ${device.tablet} {
    margin-top: 20px;
  }
`;
const translateLogo = keyframes`
  0% {
     transform: translate(45vw, 40vh);
  }


  100% {
    transform: translate(0, 0);
  }
`;
const translateLogoTablet = keyframes`
  0% {
     transform: translate(10vw, 40vh);
  }


  100% {
    transform: translate(0, 0);
  }
`;
export const SectionMain = styled.section`
  padding: 0px 1rem 0rem 1rem;
  position: relative;
  z-index: 3;
  & .section-description {
    z-index: 3;
  }
  & .small-title {
    font-family: "Century Gothic", sans-serif;
    font-size: var(--fs-largethree);
  }
  & .family-dna {
    margin-top: 60px;
  }
  & .description {
    padding-top: 40px;
    font-size: var(--fs-medium);
    & > .p-relative {
      position: relative;
      max-width: 400px;
      & > .text-abs {
        position: absolute;
        top: 30px;
        right: -290px;
        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          right: -50px;
          width: 130px;
          height: 2px;
          background-color: white;
        }
      }
    }
  }
  & .logo-section {
    position: sticky;
    top: 100px;
    left: 20px;
    display: flex;
    align-items: center;
    gap: 2rem;
    z-index: 20;
    & .section-img {
      width: var(--img-logo-dna);
      transition: transform 0.5s ease;
      transform: translate(50vw, 50vh);
      z-index: 2;
      animation: ${translateLogo} 2s ease 2.5s;
      animation-fill-mode: forwards;
      &:hover,
      &.active {
        transform: scale(1.2);
        & + .text-logo {
          & > p {
            transform: translate(0px);
            opacity: 1;
          }
        }
      }
    }

    & .text-logo {
      font-family: "Robotica";
      & > p {
        line-height: 1;
        opacity: 0;
        transition: transform 1s ease, opacity 1s ease;
      }
      & > p:first-child {
        font-size: 60px;
        transform: translate(-100px);
      }
      & > p:last-child {
        font-size: 24px;
        transform: translate(100px);
      }
    }
  }
  & .section-planet {
    max-width: clamp(18.75rem, calc(7.39rem + 56.82vw), 43.75rem);
    height: clamp(18.75rem, calc(7.39rem + 56.82vw), 43.75rem);
    margin: auto;
    text-align: center;
    background-image: linear-gradient(
      140deg,
      hsl(200deg 0% 25%) 0%,
      hsl(200deg 0% 0%) 25%,
      hsl(284deg 0% 0%) 50%,
      hsl(284deg 0% 0%) 75%,
      hsl(220deg 0% 0%) 100%
    );
    border-radius: 50%;

    display: flex;
    justify-content: center;
    filter: drop-shadow(0px -18px 30px #ffffff70);
    &:hover {
      filter: drop-shadow(0px -18px 30px #ffffff90);
    }
  }
  & .title {
    font-family: "Robotica";
    font-size: var(--fs-large);
    display: flex;
    margin: auto;
    flex-direction: column;

    & .big {
      font-size: var(--fs-xl);
    }
    & .bigX {
      font-size: var(--fs-xxl);
    }

    & .text-opacity {
      opacity: 0.5;
    }
  }
  & .section-animation-left,
  & .section-animation-right,
  & .section-animation-bottom,
  & .section-animation-top,
  & .section-animation-bottomX2 {
    transition: transform 1s linear, opacity 0.5s linear;
    opacity: 0;
    visibility: hidden;
  }
  & .section-animation-left {
    transform: translateX(-600px);
  }
  & .section-animation-right {
    transform: translateX(600px);
  }
  & .section-animation-bottom {
    transform: translateY(200px);
  }
  & .section-animation-bottomX2 {
    transform: translateY(400px);
  }
  & .section-animation-top {
    transform: translateY(-200px);
  }
  &.active-animation .section-animation-left,
  &.active-animation .section-animation-right,
  &.active-animation .section-animation-bottom,
  &.active-animation .section-animation-bottomX2,
  &.active-animation .section-animation-top {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
  }
  @media ${device.tablet} {
    text-align: center;

    & .logo-section {
      margin: 0 auto 50px;
      position: static;
      justify-content: center;
      & .section-img {
        transform: scale(1.2);
        animation: ${translateLogoTablet} 2s ease 2.5s;
        & + .text-logo {
          & > p {
            transform: translate(0px) scale(0.9);
            opacity: 1;
          }
        }
      }
    }
  }
`;
/* Animations */

// Create the keyframes
const animationRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;
export const SectionLegends = styled(SectionMain)`
  padding-top: 120px;
  & .text-relative {
    position: relative;
    & p {
      max-width: 650px;
    }
    & .container-logo-dna {
      position: absolute;
      top: 10px;
      right: -240px;
    }
  }
  & .justify-center {
    justify-content: center;
  }
  & .section-logo {
    max-width: 400px;
    max-height: 400px;
    margin: auto;
    position: relative;
    transform: rotate(20deg);
    & .logo-dna {
      position: absolute;
      inset: 0;
      display: flex;
      margin: auto;
      width: 75%;
    }
    & .text-dna {
      animation: ${animationRotate} 20s linear infinite;
    }
  }

  & .game-dna {
    max-width: 800px;
    display: flex;
    margin: 50px 0 0 210px;
    & .text-dna {
      flex-basis: 280px;
      font-family: "Obrazec";
      font-size: var(--fs-xl);
      line-height: 0.8;
      text-align: end;
      padding-right: 50px;
      color: var(--primary-color);
      & span {
        color: var(--secondary-color);
      }
    }

    & .img-dna {
      flex-basis: 520px;
      position: relative;
      & .secondary-img {
        position: absolute;
        bottom: -125px;
        left: -260px;
      }
    }
  }
  & .question-dna {
    margin-top: 50px;
    width: 641px;
    position: absolute;
    bottom: -200px;
    right: -430px;
    & .text-question {
      font-family: "Obrazec";
      font-size: var(--fs-xl);
      & .text-opacity {
        opacity: 0.5;
      }
    }

    & .row {
      justify-content: space-around;
      align-items: center;
      margin-top: 1rem;
      & .play-btn {
        width: 300px;
      }
    }
  }
  @media ${device.laptop} {
    & .justify-center {
      justify-content: flex-start;
    }
    & .small-title {
      display: block;
    }
    & .text-relative {
      & .container-logo-dna {
        bottom: -50px;
        right: -420px;
      }
    }
    & .game-dna {
      margin: 200px 0 0 0px;
      & .img-dna {
        & .secondary-img {
          bottom: -11px;
          left: -54px;
          width: 350px;
        }
      }
    }
    & .question-dna {
      width: 642px;
      bottom: -200px;
      right: -160px;
    }
    & .section-logo {
      max-width: 300px;
      max-height: 300px;
    }
  }

  @media ${device.tabletL} {
    & .text-relative .container-logo-dna {
      position: static;
      margin-top: 1.2rem;
    }
    & .question-dna {
      right: 0;
      bottom: -210px;
      position: static;
    }
    & .game-dna {
      margin: 50px 0 0 0px;
      & .img-dna {
        & .secondary-img {
          bottom: 180px;
          left: -40px;
          width: 400px;
        }
      }
    }
  }

  @media ${device.tablet} {
    & .game-dna {
      flex-direction: column;
      & .text-dna {
        flex-basis: 100px;
      }
      & .img-dna {
        & .secondary-img {
          width: 350px;
          z-index: -1;
        }
      }
    }
  }
  @media ${device.mobileL} {
    & .game-dna {
      & .img-dna {
        & .secondary-img {
          position: static;
          max-width: 380px;
          width: 100%;
        }
        & .question-dna {
          width: 100%;
          & .row {
            & .play-btn {
              width: 100%;
              margin-bottom: 1.5rem;
            }
          }
        }
      }
    }

    & .section-logo {
      max-width: calc(100vw - 200px);
      max-height: calc(100vw - 200px);
    }
  }
`;
export const SectionComingSoon = styled.section`
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: absolute;
  top: 600px;
  left: 0;
  right: 0;
  color: var(--gray-text);
  & .title-section {
    font-size: var(--fs-medium);
  }
  & .description-section {
    font-size: var(--fs-normal);
    padding-top: 30px;
  }
  @media ${device.tabletL} {
    height: 300px;
  }
  @media ${device.tablet} {
    & .description-section {
      padding-top: 10px;
    }
  }
  @media ${device.mobileL} {
    position: static;
    height: auto;
  }
`;

export const SectionComingSoonText = styled(SectionMain)`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--gray-text);
  margin-top: 200px;
  & .title-section {
    font-size: 30px;
  }
  & .description-section {
    font-size: 20px;
    padding-top: 30px;
  }
  @media ${device.mobileL} {
    min-height: 250px;
    margin-top: 50px;
  }
`;

export const SectionSocialMedia = styled.section`
  position: absolute;
  bottom: 20px;
  right: 30px;
  z-index: 3;
  & .text-contact {
    transform: rotate(-90deg) translateY(-80px);
    font-size: 20px;
  }
  & .social-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & .item-social {
      width: 30px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  @media ${device.desktop} {
    right: 0px;
  }
  @media ${device.laptop} {
    position: static;
    text-align: center;
    margin-top: 6rem;
    & .text-contact {
      transform: rotate(0deg) translateY(0px);
    }
    & .social-section {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 4rem;
    }
  }
  @media ${device.tablet} {
    margin-top: 11rem;
  }
  @media ${device.mobileL} {
    margin-top: 5rem;
  }
`;

export const SectionOurVision = styled(SectionMain)`
  padding-top: 120px;
  row-gap: 3rem;
  position: relative;

  & .image-section {
    margin-top: 1rem;
  }
  & .strong-description {
    font-size: var(--fs-largeone);
    font-family: "Century Gothic Bold", sans-serif;
  }

  & .align-bottom {
    display: flex;
    align-items: flex-end;
    & > .article-family {
      width: 100%;
      padding-left: 3rem;
      align-items: center;
    }
    & .subtitle {
      font-size: var(--fs-largetwo);
    }
  }
  & .btn-team {
    width: 245px;
    margin: 2rem auto 0;
  }

  @media ${device.tabletL} {
    & .align-bottom {
      & > .article-family {
        padding-left: 0;
        padding-top: 1rem;
      }
    }
    & .family-dna {
      padding: 0 4rem;
    }
    .description {
      & > .p-relative {
        max-width: 100vw;
        & > .text-abs {
          position: static;
          display: block;
          text-align: end;
          margin-left: 1rem;
          margin-right: 1.5rem;
          padding-left: 2rem;
          &::after {
            right: 2px;
          }
        }
      }
    }
  }
  @media ${device.tablet} {
    & .family-dna {
      padding: 0 1rem;
    }
  }
  @media ${device.mobileL} {
    padding: 100px 0rem 1rem;
  }
`;
/* Animations */

// Create the keyframes
const translate = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(30px);
    opacity: 0.5;
  }

  100% {
    transform: translateY(0);
  }
`;

export const SectionScroll = styled.section`
  margin-top: 3rem;
  animation: ${translate} 2s linear infinite;
  &.hidden {
    visibility: hidden;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-dark-text);
  }
  & .section-img {
    width: 30px;
  }
  @media ${device.tablet} {
    margin-top: 0;
  }
`;

export const SectionLanguage = styled.section`
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: 160px;
  @media ${device.desktop} {
    left: 30px;
  }
  @media ${device.laptop} {
    position: static;
    text-align: center;
    width: 150px;
    margin: 2rem auto;
  }
`;

export const SectionForm = styled.section`
  display: flex;
  & .card-form {
    padding: 4rem 2rem 2rem;
    z-index: 3;
    max-width: 500px;
    margin: auto;
    background-color: black;
    & .title-form {
      font-size: var(--fs-medium);
      font-family: "Robotica";
    }

    & .form-leads {
      padding: 2rem 0;
      & > * {
        margin-bottom: 2rem;
        &.pos-relative {
          position: relative;
          & .abs-text {
            position: absolute;
            bottom: -24px;
            right: -42px;
            font-size: 20px;
          }
        }
        &.checkPolicy {
          max-width: 100%;
        }
      }
      & input:not([type="checkbox"], [type="submit"]) {
        height: 64px;
        border: 2px solid var(--gray-sec-color);

        padding: 20px;
        width: 100%;
        font-family: "Century Gothic";
        font-size: 1rem;
        background-color: black;
        color: white;
        &::placeholder {
          color: var(--gray-sec-color);
        }
      }
      & button[type="submit"] {
        padding: 12.5px 30px;
        border: 2px solid var(--white-color);
        font-weight: bold;
        font-size: 20px;
        width: 100%;
        color: var(--white-color);
        background-color: black;
        transition: background-color 0.5s ease, color 0.5s ease;
        &:hover {
          cursor: pointer;
          color: black;
          background-color: var(--white-color);
        }
        &:disabled {
          cursor: not-allowed;
        }
        &:hover:disabled {
          background-color: var(--white-color);
        }
      }
      & .section-phone {
        display: flex;
        gap: 1rem;
        & .ui.search.dropdown > .text {
          padding-top: 0.8rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
        }
        & .ui.selection.dropdown > .delete.icon,
        .ui.selection.dropdown > .dropdown.icon,
        .ui.selection.dropdown > .search.icon {
          padding: 1.916667em 0.916667em;
        }

        & .ui.dropdown .menu > .item {
          font-size: 0.75rem;
        }

        & .ui.selection.dropdown {
          border: 2px solid var(--gray-sec-color);
          border-radius: 10px;
        }

        & .ui.dropdown:not(.button) > .default.text {
          color: var(--gray-sec-color);
        }
      }
    }
  }

  & .ui.fluid.dropdown {
    height: 64px;
    border-radius: 0px;
    background-color: black;
    border: 2px solid lightgray;
    color: white;
    padding: 20px;
  }

  & .ui.fluid.dropdown > .dropdown.icon {
    padding-top: 1.2rem;
  }
`;

export const SectionIconDNA = styled(SectionMain)`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;

  & div {
    width: 100px;
  }
`;
