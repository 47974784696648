import styled, { keyframes } from 'styled-components';

const opacityAnimation = keyframes`
  0% {
     opacity: 1;
  }


  100% {
    opacity: 0;
    z-index: 0;
  }
`;
export const StylesLoading = styled.section`
  background-color: var(--bg-loading);
  min-height: 100vh;
  padding: 0;
  margin: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 20;
  animation: ${opacityAnimation} 1.5s ease 2.5s;
  animation-fill-mode: forwards;
`;

/* Animations */

// Create the keyframes
const logoDnaLoading = keyframes`
  0% {
    transform: translateX(0);
  }


  100% {
    transform: translateX(100%);
  }
`;
// Create the keyframes
const opacityDNA = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;
export const ImageContainer = styled.div`
  width: 130px;
  position: relative;
  /*  & img {
    opacity: 0;
    transform: translateY(1000px);
    &.transition {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 1s ease, transform 1s cubic-bezier(0.28, 0.73, 0.61, 1.34);
    } 
  }*/
  & img {
    animation: ${opacityDNA} 0.5s linear 2.5s;
    animation-fill-mode: forwards;
  }
  & .black-bg-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, var(--bg-loading) 80%, transparent);
    top: 0;
    left: 0;
    animation: ${logoDnaLoading} 2s linear 0.5s;
    animation-fill-mode: forwards;
  }
`;
