import { StyleButton, StyleButtonNotify } from './styles';

export const Button = ({ text, bgColor, color, type = 'button', onPress, disabled, fontSize }) => (
  <StyleButton disabled={disabled} type={type} bgColor={bgColor} color={color} onClick={onPress} fontSize={fontSize}>
    <span>{text}</span>
  </StyleButton>
);
export const ButtonNotify = ({ text, bgColor, color, type = 'button', onPress, disabled, fontSize }) => (
  <StyleButtonNotify
    disabled={disabled}
    type={type}
    bgColor={bgColor}
    color={color}
    onClick={onPress}
    fontSize={fontSize}
  >
    <span>{text}</span>
  </StyleButtonNotify>
);
