const size = {
  mobileM: '375px', //xs
  mobileL: '576px', //s
  tablet: '768px', //m
  tabletL: '1024px', //l
  laptop: '1440px', //xl
  desktop: '1660px',
};

export const device = {
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptop: `(max-width: ${size.laptop})`,
  desktop: `(max-width: ${size.desktop})`,
};
