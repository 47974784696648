import styled from 'styled-components';

export const StyleButton = styled.button`
  width: 100%;
  background-color: ${(props) => props.bgColor || 'transparent'};
  font-family: 'Century Gothic', sans-serif;
  color: ${(props) => props.color || '#FFFFFF'};
  border: 1px solid white;
  border-color: ${(props) => props.bgColor || '#FFFFFF'};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  font-size: ${(props) => props.fontSize || 'var(--fs-medium)'};
  font-weight: bold;
  outline: none;
  position: relative;
  display: inline-block;
  min-width: 150px;
  border-radius: 2rem;
  & span {
    color: #ffffff;
    transition: 0.2s;
    position: relative;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 2px;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.3s;
    border-radius: 2rem;
  }
  &::before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 0%;
    background-color: ${(props) => props.color || '#FFFFFF'};
    transition: 0.3s ease-out;
    border-radius: 2rem;
  }
  &:hover span {
    color: ${(props) => props.bgColor || '#000000'};
    transition: 0.3s 0.1s;
    border-radius: 2rem;
  }
  &:hover::before {
    width: 100%;
    border-radius: 2rem;
  }
`;

export const StyleButtonNotify = styled.button`
  width: 100%;

  background-image: linear-gradient(135deg, #262d4b, #445685, #262d4b);
  transition: background-image 0.7s ease-in-out;
  font-family: 'Century Gothic', sans-serif;
  color: ${(props) => props.color || '#FFFFFF'};

  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  font-size: ${(props) => props.fontSize || 'var(--fs-medium)'};
  font-weight: bold;
  outline: none;
  position: relative;
  display: inline-block;
  min-width: 150px;

  box-shadow: 0px 0px 10px 5px rgb(56, 80, 130, 0.54);
  border: none;

  &:hover {
    background-image: linear-gradient(45deg, #262d4b, #445685, #262d4b);
  }
`;
