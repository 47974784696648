export const translationsEN = {
  /* Section Coming Soon */
  'COMING SOON': {
    title: 'Here begins a new',
    universe: 'Universe',
    'Expanding universe': 'Expanding universe',
    'Come back': 'Come back soon (Maybe just a few light-weeks)',
  },
  /* Footer */
  footer: {
    title: '®DNA Universe. All Right Reserved',
  },
  /* Common */
  'Contact us': 'Contact us',
};
