import styles from "../styles";

const StartSteps = ({ imgUrl, title }) => (
  <div className={`${styles.flexCenter} flex-row`}>
    <div
      className={`${styles.flexCenter} w-[70px] h-[70px] rounded-[24px] bg-[#323F5D]`}
    >
      <img src={imgUrl} alt="dna" className="w-1/2 h-1/2 object-contain  " />
    </div>
    <p className="flex-1 ml-[30px] font-bold text-[25px] text-[#B0B0B0] leading-[32.4px]">
      {title}
    </p>
  </div>
);

export default StartSteps;
