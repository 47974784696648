import styled from 'styled-components';

export const StylesNotFound = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  color: white;
  font-size: var(--fs-largetwo);
  font-family: 'Robotica';
`;
